'use client';

import { SignInRequestBody } from 'bff';
import { requiredFieldMessage } from 'piramid-constants';
import { z } from 'zod';
import { Authenticate } from './Authenticate';
import { LoginUserFormProps } from 'shared-components/hooks/useVerifyAccount';
import { loginUser } from '@/lib/loginUser';
import { UserType } from 'database';

const schema = z.object({
  email: z.string().email().min(1, requiredFieldMessage),
  password: z.string().min(1, requiredFieldMessage),
});

type FormData = z.infer<typeof schema>;

export type SignInIDPFn = (data: SignInRequestBody) => Promise<any>;

export const LoginUserForm = ({
  onDone,
  forgotPasswordHref,
  children,
  onVerificationCodeOpen,
  inverseAction,
  type,
}: LoginUserFormProps & {
  type: UserType;
}) => {
  return (
    <Authenticate
      context='login'
      title='Inicia sesión'
      description={`Liquida reclamos, administra licitaciones y mucho más.`}
      back={{
        label: 'Volver al login',
      }}
      submit={{
        label: 'Iniciar sesión',
        handle: (data) => loginUser(type, data),
        forceVerification: false,
      }}
      inverseAction={inverseAction}
      callbacks={{
        onDone,
      }}
    />
  );
};
