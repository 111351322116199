'use client';

import { Button, toast, useToast } from 'ui';
import { Input } from 'ui';
import { useState, useCallback } from 'react';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  InputOTPSeparator,
} from 'ui/input-otp';
import { BackToButton, useApiError } from 'shared-components';

const ResendVerificationCodeButton = ({
  onResendVerificationCode,
}: {
  onResendVerificationCode: () => Promise<any>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [resent, setResent] = useState(false);

  const { toast } = useToast();

  const handleClick = useCallback(() => {
    setIsLoading(true);
    onResendVerificationCode()
      .then(() => {
        toast({
          title: 'Código de verificación reenviado',
          description:
            'El código de verificación ha sido reenviado correctamente',
        });

        setResent(true);
      })
      .finally(() => setIsLoading(false));
  }, [onResendVerificationCode]);

  if (resent) return null;

  return (
    <Button
      variant='link'
      className='text-piramid-black font-normal p-0 h-auto text-sm'
      onClick={handleClick}
      loading={isLoading}
    >
      No recibí el email, quiero un nuevo código.
    </Button>
  );
};

export const VerificationCode = ({
  onDone,
  email,
  onResendVerificationCode,
  verifyAccount,
}: {
  onDone: () => void;
  email: string;
  onResendVerificationCode: () => Promise<any>;
  verifyAccount: (data: {
    body: {
      verificationCode: string;
      email: string;
    };
  }) => Promise<any>;
}) => {
  const [verificationCode, setVerificationCode] = useState('');

  const { handleError } = useApiError();

  const [isLoading, setIsLoading] = useState(false);

  const { toast } = useToast();

  const handleSubmit = useCallback(() => {
    setIsLoading(true);

    verifyAccount({
      body: {
        verificationCode,
        email,
      },
    })
      .then(() => {
        toast({
          title: 'Cuenta verificada',
          description: 'Tu cuenta ha sido verificada correctamente',
        });

        onDone();
      })
      .catch((err) => {
        handleError(err);
        setIsLoading(false);
      });
  }, [verificationCode, email]);

  return (
    <div className='w-full relative animate-fadein'>
      <h1 className='text-[32px] font-semibold leading-[1.3] tracking-tight'>
        Revisa tu bandeja
        <br />
        de entrada
      </h1>
      <div className='mt-4 mb-9'>
        <p className='text-[24px] font-semibold text-stone-300 leading-[30px] tracking-tight'>
          Ingresa el código de verificación de 6 dígitos <br />
          que enviamos a tu email.
        </p>
      </div>

      <div>
        <div className='mb-9'>
          <InputOTP
            value={verificationCode}
            onChange={(value) => setVerificationCode(value)}
            maxLength={6}
          >
            <InputOTPGroup className='w-full'>
              <InputOTPSlot
                className='flex-1 h-[54px] border-stone-300 font-bold text-2xl'
                activeClassName='ring-2 ring-piramid-orange'
                index={0}
              />
              <InputOTPSlot
                className='flex-1 h-[54px] border-stone-300 font-bold text-2xl'
                activeClassName='ring-2 ring-piramid-orange'
                index={1}
              />
              <InputOTPSlot
                className='flex-1 h-[54px] border-stone-300 font-bold text-2xl'
                activeClassName='ring-2 ring-piramid-orange'
                index={2}
              />
              <InputOTPSlot
                className='flex-1 h-[54px] border-stone-300 font-bold text-2xl'
                activeClassName='ring-2 ring-piramid-orange'
                index={3}
              />
              <InputOTPSlot
                className='flex-1 h-[54px] border-stone-300 font-bold text-2xl'
                activeClassName='ring-2 ring-piramid-orange'
                index={4}
              />
              <InputOTPSlot
                className='flex-1 h-[54px] border-stone-300 font-bold text-2xl'
                activeClassName='ring-2 ring-piramid-orange'
                index={5}
              />
            </InputOTPGroup>
          </InputOTP>
        </div>

        <Button
          onClick={handleSubmit}
          loading={isLoading}
          className='w-full h-[45px] bg-piramid-black rounded-[10px]'
        >
          Continuar
        </Button>
      </div>

      <div className='text-center'>
        <div className='mt-4'>
          <ResendVerificationCodeButton
            onResendVerificationCode={onResendVerificationCode}
          />
        </div>
      </div>
    </div>
  );
};
