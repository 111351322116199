'use client';
import { loginUser } from '@/lib/loginUser';
import { signInIDP } from '@/lib/signInIDP';
import { useRouter } from 'next/navigation';
import React, { useState } from 'react';
import { UserTypeTabs } from './UserTypeTabs';
import { UserType } from 'database';
import { LoginUserForm } from './LoginUserForm';

const authenticationInverseActionContent = {
  login: {
    destination: 'Inicia sesión',
    question: '¿Ya tienes una cuenta?',
  },
  register: {
    destination: 'Regístrate',
    question: '¿No tienes una cuenta?',
  },
};

export const Login = () => {
  const router = useRouter();

  const [type, setType] = useState<UserType>('field_adjuster');

  return (
    <div className='space-y-4 w-full'>
      <UserTypeTabs onChange={setType} value={type} />
      <LoginUserForm
        type={type}
        inverseAction={{
          type: 'path',
          path: '/register',
          ...authenticationInverseActionContent.register,
        }}
        onDone={() => router.push('/')}
      />
    </div>
  );
};
