import { httpClient } from '@/http/client';
import { LoginUserRequestBody } from 'bff';
import { UserType } from 'database';

export const credentialsResendAccountVerification = async ({
  body,
}: {
  body: LoginUserRequestBody;
}) =>
  await httpClient
    .headers({
      'x-user-type':
        'field_adjuster vendor' satisfies `${UserType} ${UserType}`,
    })
    .post(body, '/v1/auth/verification/resend/credentials')
    .json();
