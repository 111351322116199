'use client';
import { useRouter } from 'next/navigation';
import { useCallback, useState } from 'react';
import {
  AuthenticateForm,
  AuthenticateFormData,
  AuthenticateFormProps,
  BackToButton,
  PiramidLogo,
  AuthenticateProps,
  AuthenticateContainer,
  AuthenticateLogo,
  AuthenticateTitle,
  AuthenticateDescription,
  AuthenticationIDPContainer,
  AuthenticationMoreOptions,
  AuthenticateTOS,
} from 'shared-components';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  useToast,
} from 'ui';
import { signInIDP } from '../lib/signInIDP';
import { useVerifyAccount } from 'shared-components/hooks/useVerifyAccount';
import { VerificationCode } from 'shared-components/components/VerificationCode';
import { verifyAccount } from '@/lib/verifyAccount';
import { credentialsResendAccountVerification } from '@/lib/credentialsResendAccountVerification';

export const Authenticate = ({
  title,
  description,
  inverseAction,
  context,
  back,
  submit,
  callbacks,
}: AuthenticateProps) => {
  const [showLocalStrategy, setShowLocalStrategy] = useState(false);
  const router = useRouter();

  const {
    otpCredentials,
    handleResendVerificationCode,
    handleUserNotVerified,
    clearOtpCredentials,
    setOtpCredentials,
  } = useVerifyAccount({
    credentialsResendAccountVerification,
  });

  const { toast } = useToast();

  const handleBack = useCallback(() => {
    setShowLocalStrategy(false);
    clearOtpCredentials();
  }, []);

  const handleSubmit = useCallback(
    async (data: AuthenticateFormData) => {
      await submit.handle(data);

      if (submit.forceVerification) return setOtpCredentials(data);

      callbacks.onDone();
    },
    [submit],
  );

  if (otpCredentials)
    return (
      <AuthenticateContainer>
        <VerificationCode
          verifyAccount={verifyAccount}
          email={otpCredentials.email}
          onDone={callbacks.onDone}
          onResendVerificationCode={() =>
            handleResendVerificationCode(otpCredentials!)
          }
        />

        <div className='mt-9'>
          <BackToButton handleBack={handleBack}>{back.label}</BackToButton>
        </div>
      </AuthenticateContainer>
    );

  return (
    <AuthenticateContainer>
      <AuthenticateLogo />
      <AuthenticateTitle>{title}</AuthenticateTitle>

      <AuthenticateDescription>{description}</AuthenticateDescription>

      <AuthenticateForm
        submit={{
          label: submit.label,
          handle: handleSubmit,
        }}
        context={context}
        callbacks={{
          user_not_verified: handleUserNotVerified,
        }}
      />

      <AuthenticationMoreOptions
        inverseAction={inverseAction}
        handleContinueWithEmail={() => setShowLocalStrategy(true)}
        withEmailContinue={false}
      />
    </AuthenticateContainer>
  );
};
