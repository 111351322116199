import { useCallback, useState } from 'react';
import { toast, useToast } from 'ui';
import { SignInIDPFn } from '../components/SignInWithGoogle';
import { AuthenticateProps } from '../components/Authenticate';

export interface LoginFormData {
  email: string;
  password: string;
}

export interface LoginUserFormProps
  extends Pick<AuthenticateProps, 'inverseAction'> {
  signInIDP: SignInIDPFn;
  onDone: () => void;
  forgotPasswordHref?: string;
  children?: React.ReactNode;
  onVerificationCodeOpen?: (open: boolean) => void;
}

const msToMinutes = (ms: number) => Math.floor(ms / 60000);

export const useVerifyAccount = ({
  credentialsResendAccountVerification,
}: {
  credentialsResendAccountVerification: (input: {
    body: LoginFormData;
  }) => Promise<any>;
}) => {
  const [otpCredentials, setOtpCredentials] = useState<LoginFormData | null>(
    null,
  );

  const { toast } = useToast();

  const handleResendVerificationCode = useCallback(
    (credentials: LoginFormData) =>
      credentialsResendAccountVerification({
        body: credentials,
      }),
    [],
  );

  const handleUserNotVerified = useCallback(
    async ({
      credentials,
      errorMetadata,
      onVerificationCodeOpen,
    }: Parameters<
      NonNullable<LoginUserFormProps['callbacks']>['user_not_verified']
    >[0] & {
      onVerificationCodeOpen?: (open: boolean) => void;
    }) => {
      if (!errorMetadata.can_resend) {
        toast({
          title: 'Cuenta sin verificar',
          description: `Debido a intentos fallidos de verificación, debes esperar ${msToMinutes(
            errorMetadata.wait_time_ms,
          )} minutos antes de poder reenviar el código de verificación`,
        });

        return false;
      }

      await handleResendVerificationCode(credentials);

      setOtpCredentials(credentials);
      onVerificationCodeOpen?.(true);
      return true;
    },
    [],
  );

  const clearOtpCredentials = useCallback(() => {
    setOtpCredentials(null);
  }, []);

  return {
    setOtpCredentials,
    otpCredentials,
    handleResendVerificationCode,
    handleUserNotVerified,
    clearOtpCredentials,
  };
};
